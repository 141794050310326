import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import validator from "validator";
import { DEFAULT_THEME } from "../../themes/themes";
import { DEFAULT_PROFILEPICTURE, DEFAULT_HEADERPICTURE } from "../../API/api";
export default function SignUp({ onClose }) {
  const navigate = useHistory();
  const [usedTheme, setUsedTheme] = useState(DEFAULT_THEME);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const validate = async () => {
    setError(false);
    if (!validator.isEmail(email)) {
      setError("Enter valid email");
      return;
    }
    if (password.length < 5) {
      setError("Password must be at least 6 characters");
      return;
    }
    if (name.length < 3) {
      setError("Name must be at least 3 characters");
      return;
    }
    signup();
  };
  const signup = async () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((resp) => {
        firebase.auth().currentUser.updateProfile({
          displayName: name,
          photoURL: DEFAULT_PROFILEPICTURE,
        });
        firebase
          .firestore()
          .collection("users")
          .doc(resp.user.uid)
          .get()
          .then((response) => {
            if (response.exists) {
            } else {
              onClose();
              firebase
                .firestore()
                .collection("users")
                .doc(resp.user.uid)
                .set({
                  uid: resp.user.uid,
                  email,
                  displayName: name,
                  profilePictureURL: DEFAULT_PROFILEPICTURE,
                  headerPictureURL: DEFAULT_HEADERPICTURE,
                  created_at: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                 // navigate.push("/edit/" + resp.user.uid);
                  setEmail("");
                  setPassword("");
                  setName("");
                });
            }
          });
      })
      .catch(() => {
        setError("User already exists");
      });
  };
  return (
    <div
      style={{
        width: "94%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        paddingTop: 16,
      }}
    >
      <label
        style={{
          color: usedTheme.TEXT,
          fontWeight: "700",
          fontSize: 24,
          alignSelf: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        Register
      </label>
      <div>
        <label
          style={{
            color: usedTheme.TEXT,
            fontWeight: "400",
            fontSize: 16,
            marginTop: -4,
          }}
        >
          Name
        </label>
        <input
          className="form-control"
          placeholder="Enter your name"
          style={{
            color: usedTheme.TEXT,
            fontSize: 14,
            marginTop: -10,
            marginBottom: 0,
          }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div>
        <label
          style={{
            color: usedTheme.TEXT,
            fontWeight: "400",
            fontSize: 16,
            marginTop: 4,
          }}
        >
          Email
        </label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter your email"
          style={{ color: usedTheme.TEXT, fontSize: 14, marginTop: -10 }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div>
        <label
          style={{
            color: usedTheme.TEXT,
            fontWeight: "400",
            fontSize: 16,
            marginTop: 4,
          }}
        >
          Password
        </label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter your password"
          style={{ color: usedTheme.TEXT, fontSize: 14, marginTop: -10 }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <div>
        <label style={{ color: "red" }}>{error}</label>
      </div>
      <button
        className="btn btn-dark btn-lg btn-block"
        style={{
          backgroundColor: usedTheme.PRIMARY,
          borderWidth: 0,
          alignSelf: "center",
        }}
        onClick={() => {
          validate();
        }}
      >
        <label
          style={{ color: usedTheme.TEXT, fontWeight: "600", fontSize: 18 }}
        >
          Register
        </label>
      </button>
    </div>
  );
}
