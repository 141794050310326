import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  SaveProfilePicture,
  SaveHeaderPicture,
  SaveWorkPicture,
  GetUserInfo,
  SaveUpdates,
  DEFAULT_PROFILEPICTURE,
  DEFAULT_HEADERPICTURE,
} from "./API/api";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import QrCodeTemplate from "./component/qrCode.component";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { DEFAULT_THEME, Themes_list } from "./themes/themes";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import $ from "jquery";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import {
  MdModeEditOutline,
  MdOutlineSaveAlt,
  MdEmail,
  MdPhoneIphone,
  MdWebAsset,
  MdCameraAlt,
  MdRefresh,
} from "react-icons/md";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
  FaBehance,
  FaRegCopy,
  FaSkype,
  FaWhatsapp,
} from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import validator from "validator";
import Cropper from "react-easy-crop";
import getCroppedImg from "./API/tools/cropImage";
import HashLoader from "react-spinners/HashLoader";
import i18n from "./i18n";
import Switch from "react-switch";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const overrideHash = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const FRONTEND_URL = "https://www.knot.services";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const languages = [
  { label: "English", value: "en" },
  { label: "Espanol", value: "es" },
];
const prefixes = ["Mr.", "Mrs.", "Ms."];
const fullListPrefixes = [
  "Mr.",
  "Mrs.",
  "Ms.",
  "Prof.",
  "Dean.",
  "Dr.",
  "Eng.",
  "Lt.",
  "Sg.",
  "Cmd.",
  "Col.",
  "Gen.",
  "CEO",
  "None",
];
function EditUserInfoScreen({ match }) {
  const navigate = useHistory();
  const inputProfilePic = useRef(null);
  const headerProfilePic = useRef(null);
  const workProfilePic = useRef(null);
  const qrCodeRef = useRef(null);

  const { innerWidth: width, innerHeight: height } = window;
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [usedTheme, setUsedTheme] = useState(DEFAULT_THEME);
  const [isMobile, setIsMobile] = useState(false);
  const [years, setYears] = useState([2021]);

  useEffect(() => {
    let i = 0;
    let yr = [2021];
    for (i = 1; i < 100; i++) {
      yr.push(2021 - i);
    }
    setYears(yr);
  }, []);
  const handleResize = () => {
    if (window.innerWidth < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, []);
  const [profileURL, setProfileURL] = useState(
    FRONTEND_URL + "/user/" + match.params.id
  );
  const [currentUser, setCurrentUser] = useState({ isSigned: false });
  const [userInfo, setUserInfo] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState(
    DEFAULT_PROFILEPICTURE
  );
  const [profilePictureFile, setProfilePictureFile] = useState();
  const [croppedProfilePictureURL, setCroppedProfilePictureURL] = useState();
  const [croppedProfilePictureFile, setCroppedProfilePictureFile] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onProfilePhotoCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );
  const [profilePictureModalVisible, setProfilePictureModalVisible] =
    useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [headerPictureURL, setHeaderPictureURL] = useState(
    DEFAULT_HEADERPICTURE
  );
  const [headerPictureFile, setHeaderPictureFile] = useState();

  const [namePrefix, setNamePrefix] = useState("Mr.");
  const [allPrefixesVisible, setAllPrefixesVisible] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("+");
  const [cellPhoneHasWhatsapp, setCellPhoneHasWhatsapp] = useState(false);
  const [website, setWebsite] = useState("");

  const [additionalPhoneNumbers, setAdditionalPhoneNumbers] = useState([]);
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [behance, setBehance] = useState("");
  const [skype, setSkype] = useState("");

  const [birthdayYear, setBirthdayYear] = useState("");
  const [birthdayMonth, setBirthdayMonth] = useState("");
  const [birthdayDay, setBirthdayDay] = useState("");

  const [workTitle, setWorkTitle] = useState("");
  const [workOrganization, setWorkOrganization] = useState("");
  const [workPictureURL, setWorkPictureURL] = useState("");
  const [workPictureFile, setWorkPictureFile] = useState();
  const [workPhone, setWorkPhone] = useState("");
  const [workPhoneExt, setWorkPhoneExt] = useState("");
  const [workPhoneHasWhatsapp, setWorkPhoneHasWhatsapp] = useState(false);
  const [additionalWorkPhoneNumbers, setAdditionalWorkPhoneNumbers] = useState(
    []
  );
  const [workEmail, setWorkEmail] = useState("");
  const [workWebsite, setWorkWebsite] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [language, setLanguage] = useState("en");
  useEffect(() => {
    let us = JSON.parse(localStorage.getItem("user"));
    if (us && us.isSigned) {
      setCurrentUser(us);
    } else {
      setCurrentUser(false);
    }
  }, []);
  useEffect(() => {
    if (
      currentUser?.isSigned &&
      currentUser.uid &&
      match.params.id == currentUser.uid
    ) {
      //if (match.params.id) {
      GetUserInfo({ id: match.params.id })
        .then((resp) => {
          console.log(resp);
          if (!resp?.userInfo) {
            return;
          }
          setUserInfo(resp?.userInfo);
          setLanguage(resp?.userInfo?.language);
          i18n.changeLanguage(resp?.userInfo?.language);
          setUsedTheme(resp?.userInfo?.THEME || DEFAULT_THEME);
          setProfilePictureURL(resp?.userInfo?.profilePictureURL || "");
          setCroppedProfilePictureURL(resp?.userInfo?.profilePictureURL || "");
          setHeaderPictureURL(resp?.userInfo?.headerPictureURL || "");
          setNamePrefix(resp?.userInfo?.namePrefix || "");
          setFirstName(resp?.userInfo?.firstName || "");
          setMiddleName(resp?.userInfo?.middleName || "");
          setLastName(resp?.userInfo?.lastName || "");
          setDescription(resp?.userInfo?.description || "");
          setEmail(resp?.userInfo?.email || "");
          setCellPhone(resp?.userInfo?.cellPhone || "+");
          setCellPhoneHasWhatsapp(
            resp?.userInfo?.cellPhoneHasWhatsapp || false
          );
          setAdditionalPhoneNumbers(
            resp?.userInfo?.additionalPhoneNumbers || []
          );
          setSkype(resp?.userInfo?.skyp || "");
          setWorkPictureURL(resp?.userInfo?.workPictureURL || "");
          setWorkTitle(resp?.userInfo?.workTitle || "");
          setWorkPhone(resp?.userInfo?.workPhone || "+");
          setWorkPhoneExt(resp?.userInfo?.workPhoneExt || "");
          setWorkPhoneHasWhatsapp(
            resp?.userInfo?.workPhoneHasWhatsapp || false
          );
          setAdditionalWorkPhoneNumbers(
            resp?.userInfo?.additionalWorkPhoneNumbers || []
          );
          setWorkEmail(resp?.userInfo?.workEmail || "");
          setWorkOrganization(resp?.userInfo?.workOrganization || "");
          setWorkWebsite(resp?.userInfo?.workWebsite || "");

          setFacebook(resp?.userInfo?.facebook || "");
          setInstagram(resp?.userInfo?.instagram || "");
          setTwitter(resp?.userInfo?.twitter || "");
          setBehance(resp?.userInfo?.behance || "");
          setYoutube(resp?.userInfo?.youtube || "");
          setLinkedIn(resp?.userInfo?.linkedIn || "");
          setWebsite(resp?.userInfo?.website || "");
          setLanguage(resp?.userInfo?.language || "en");

          setBirthdayDay(resp?.userInfo?.birthdayDay);
          setBirthdayMonth(resp?.userInfo?.birthdayMonth);
          setBirthdayYear(resp?.userInfo?.birthdayYear);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentUser, match.params.id]);

  const handleSaveQR = async () => {
    exportComponentAsPNG(qrCodeRef, {
      fileName:
        (userInfo?.firstName ? userInfo?.firstName : userInfo?.displayName) +
        (userInfo?.lastName ? " " + userInfo?.lastName : ""),

      pdfOptions: {
        x: 512,
        y: 512,
        w: 512,
        h: 512,
        unit: "px",
        orientation: "p",
      },
    });
  };
  const handleSave = async () => {
    setErrorMessage(false);
    setProfileUpdated(false);
    if (email && !validator.isEmail(email)) {
      setErrorMessage("Please enter a valid email");
      return;
    }

    if (workEmail && !validator.isEmail(workEmail)) {
      setErrorMessage("Please enter a valid work email");
      return;
    }

    let cellPhone_ = cellPhone == "+" ? "" : cellPhone;
    let workPhone_ = workPhone == "+" ? "" : workPhone;
    let website_ = website
      .replace(/^https?:\/\//i, "")
      .replace(/^http?:\/\//i, "");
    let workWebsite_ = workWebsite
      .replace(/^https?:\/\//i, "")
      .replace(/^http?:\/\//i, "");
    let facebook_ =
      facebook.substring(0, 1) == "@"
        ? facebook.substring(1, facebook.length)
        : facebook;
    let linkedIn_ =
      linkedIn.substring(0, 1) == "@"
        ? linkedIn.substring(1, linkedIn.length)
        : linkedIn;
    let twitter_ =
      twitter.substring(0, 1) == "@"
        ? twitter.substring(1, twitter.length)
        : twitter;
    let youtube_ =
      youtube.substring(0, 1) == "@"
        ? youtube.substring(1, youtube.length)
        : youtube;
    let behance_ =
      behance.substring(0, 1) == "@"
        ? behance.substring(1, behance.length)
        : behance;
    let instagram_ =
      instagram.substring(0, 1) == "@"
        ? instagram.substring(1, instagram.length)
        : instagram;
    let skype_ =
      skype.substring(0, 1) == "@" ? skype.substring(1, skype.length) : skype;

    let updates = {
      namePrefix: namePrefix == "None" ? "" : namePrefix,
      firstName,
      middleName,
      lastName,
      description,
      email,
      cellPhone: cellPhone_,
      cellPhoneHasWhatsapp,
      website: website_,
      additionalPhoneNumbers: additionalPhoneNumbers.filter(
        (x) => x.phoneNumber && x.phoneNumber != "" && x.phoneNumber?.length > 2
      ),
      workTitle,
      workWebsite: workWebsite_,
      workOrganization,
      workEmail,
      workPhone: workPhone_,
      workPhoneHasWhatsapp,
      workPhoneExt,
      additionalWorkPhoneNumbers: additionalWorkPhoneNumbers.filter(
        (x) => x.phoneNumber && x.phoneNumber != "" && x.phoneNumber?.length > 2
      ),
      facebook: facebook_,
      linkedIn: linkedIn_,
      twitter: twitter_,
      youtube: youtube_,
      behance: behance_,
      instagram: instagram_,
      skype: skype_,

      THEME: usedTheme,
      language,
    };

    if (birthdayDay && birthdayMonth && birthdayYear) {
      updates.birthdayDay = birthdayDay;
      updates.birthdayMonth = birthdayMonth;
      updates.birthdayYear = birthdayYear;
    }

    if (profilePictureFile && profilePictureURL != userInfo.profilePictureURL) {
      if (croppedProfilePictureURL == DEFAULT_PROFILEPICTURE) {
        setLoading(true);
        SaveUpdates({
          id: currentUser.uid,
          updates: {
            profilePictureURL: DEFAULT_PROFILEPICTURE,
          },
        })
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setErrorMessage("Couldn't save your profile picture");
            setLoading(false);
          });
      } else {
        setLoading(true);
        await SaveProfilePicture({
          id: currentUser.uid,
          profilePicture: profilePictureFile,
        })
          .then(() => {
            setLoading(false);
          })
          .catch((err) => {
            setErrorMessage("Couldn't save your profile picture");
            setLoading(false);
          });
      }
    }

    if (headerPictureFile && headerPictureURL != userInfo.headerPictureURL) {
      setLoading(true);
      await SaveHeaderPicture({
        id: currentUser.uid,
        headerPicture: headerPictureFile,
      })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setErrorMessage("Couldn't save your header");
          setLoading(false);
        });
    } else if (
      !headerPictureFile &&
      headerPictureURL != userInfo.headerPictureURL &&
      headerPictureURL == DEFAULT_HEADERPICTURE
    ) {
      updates.headerPictureURL = DEFAULT_HEADERPICTURE;
    }

    if (workPictureFile && workPictureURL != userInfo.workPictureURL) {
      setLoading(true);
      await SaveWorkPicture({
        id: currentUser.uid,
        workPicture: workPictureFile,
      })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    setLoading(true);
    SaveUpdates({
      id: currentUser.uid,
      updates,
    })
      .then(() => {
        setProfileUpdated(true);
        setLoading(false);
      })
      .catch(() => {
        setErrorMessage("An error occured, please try again");
        setLoading(false);
      });
  };

  const AddAdditionalPhoneNumber = () => {
    setAdditionalPhoneNumbers([
      ...additionalPhoneNumbers,
      { phoneNumber: "+", phoneNumberExtension: "", hasWhatsapp: false },
    ]);
    setRefresh(!refresh);
  };

  const UpdateAdditionalPhoneNumber = (idx, updates) => {
    setAdditionalPhoneNumbers(
      additionalPhoneNumbers.map((item, index) => {
        if (index === idx) {
          return { ...item, ...updates };
        } else {
          return item;
        }
      })
    );
    setRefresh(!refresh);
  };

  const AddAdditionalWorkPhoneNumber = () => {
    setAdditionalWorkPhoneNumbers([
      ...additionalWorkPhoneNumbers,
      { phoneNumber: "+", phoneNumberExtension: "", hasWhatsapp: false },
    ]);
    setRefresh(!refresh);
  };

  const UpdateAdditionalWorkPhoneNumber = (idx, updates) => {
    setAdditionalWorkPhoneNumbers(
      additionalWorkPhoneNumbers.map((item, index) => {
        if (index === idx) {
          return { ...item, ...updates };
        } else {
          return item;
        }
      })
    );
    setRefresh(!refresh);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      var head = $(".edit_user_header");
      var stick = "hidden";

      $(window).scroll(function () {
        $(window).scrollTop() > 0
          ? head.addClass(stick)
          : head.removeClass(stick);
      });
    });
  }, []);
  let imgMyimageexample = require("./images/home7/banner-bg.png");
  let imgMybgexample = require("./images/bg1.jpg");
  if (!userInfo.profilePictureURL && !userInfo.displayName) {
    return (
      <div className="loading_screen">
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            //backgroundColor: "#d4af36",
            //backgroundImage: `url(${imgMyimageexample})`,
            headerPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <HashLoader
            color={"#fff"}
            loading={true}
            css={overrideHash}
            size={100}
          />
          {/*<img
            src={require("./images/animations/loading3.gif")}
            alt="loading..."
            style={{ width: isMobile ? "33%" : "16%" }}
          />*/}
        </div>
      </div>
    );
  } else {
    return (
      <div className="loading_screen">
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            //backgroundColor: "#403a2fff",
            //backgroundImage: `url(${imgMyimageexample})`,
            headerPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingTop: 80,
          }}
        >
          <nav
            id="fixed-top"
            className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg edit_user_header"
            style={{ paddingTop: 0 }}
          >
            <div className="container" style={{}}>
              <Link to="/" style={{ width: "50%" }}>
                <span className="navbar-brand">
                  <img
                    src={require("./images/logo_knot.png")}
                    alt="logo"
                    style={{ width: "54%", borderRadius: 12 }}
                  />
                </span>
              </Link>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignSelf: "center",
                  marginTop: 10,
                  width: "50%",
                }}
              >
                <img
                  src={currentUser?.photoURL}
                  style={{
                    //height: 56,
                    color: "#fff",
                    borderRadius: 100,
                    width: "50%",
                    maxWidth: 56,
                    maxHeight: 56,
                    aspectRatio: 1,
                    border: "4px solid #f5ad29",
                  }}
                  onClick={() => {}}
                  alt=""
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingRight: 20,
                      paddingLeft: 8,
                    }}
                  >
                    <label
                      className="nav-item"
                      style={{
                        //color: usedTheme.TEXT,
                        fontSize: 20,
                        fontWeight: "bold",
                        marginBottom: -4,
                      }}
                      onClick={() => {}}
                    >
                      {currentUser?.displayName}
                    </label>
                  </div>
                  <label
                    className="nav-item"
                    style={{
                      //color: usedTheme.TEXT,
                      fontSize: 10,
                      fontWeight: "400",
                      alignSelf: "flex-start",
                      marginTop: -4,
                      paddingLeft: 8,
                    }}
                    onClick={() => {
                      firebase.auth().signOut();
                      navigate.push("/home");
                    }}
                  >
                    {"Signout"}
                  </label>
                </div>
              </div>
            </div>
          </nav>
          <CSSTransition
            in={profilePictureModalVisible}
            timeout={300}
            classNames="dialog"
          >
            <Modal
              closeTimeoutMS={500}
              isOpen={profilePictureModalVisible}
              style={{
                content: {
                  zIndex: 13,
                  maxWidth: 400,
                  //minWidth: 340,
                  width: "80%",
                  height: 380,
                  borderRadius: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                  marginLeft: isMobile
                    ? 0
                    : width / 2 -
                      (width * 0.9 > 400
                        ? 400
                        : width * 0.9 < 300
                        ? 300
                        : 0.9 * width) /
                        2,
                },
                overlay: {
                  backgroundColor: "#ffffff00",
                },
              }}
            >
              <button
                onClick={() => {
                  setProfilePictureModalVisible(false);
                }}
                style={{
                  position: "absolute",
                  right: 10,
                  top: 10,
                  backgroundColor: "#fff",
                  borderWidth: 0,
                }}
              >
                <CgClose size={20} color={usedTheme.TEXT} />
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  width: "90%",
                  position: "relative",
                  aspectRatio: 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: 200,
                    height: 200,
                    maxWidth: 200,
                    maxHeight: 200,
                    marginTop: 20,
                    paddingTop: 20,
                  }}
                >
                  <div
                    className="get-btn remove-pp"
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: 20,
                      position: "absolute",
                      top: 6,
                      right: 6,
                      padding: 0,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 999,
                      backgroundColor: usedTheme.PRIMARY,
                      borderWidth: 0,
                    }}
                    onClick={() => {
                      setCroppedProfilePictureURL(DEFAULT_PROFILEPICTURE);
                    }}
                  >
                    <CgClose size={20} color={"#fff"} />
                  </div>
                  <Cropper
                    style={{ maxWidth: 100, maxHeight: 100 }}
                    image={croppedProfilePictureURL}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onProfilePhotoCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <button
                  className="get-btn"
                  onClick={() => {
                    inputProfilePic.current.click();
                  }}
                  style={{
                    width: 50,
                    height: 40,
                    borderRadius: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: usedTheme.PRIMARY,
                    borderWidth: 0,
                  }}
                >
                  <label style={{ fontSize: 16, color: "#fff" }}>
                    {i18n.t("Edit")}
                  </label>
                </button>
                <button
                  className="get-btn"
                  onClick={() => {
                    getCroppedImg(croppedProfilePictureURL, croppedAreaPixels)
                      .then(async (resp) => {
                        //setProfilePictureURL(resp);
                        const response = await fetch(resp);
                        const data = await response.blob();
                        let file = new File([data], "Image");
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                          setProfilePictureFile(reader.result);
                          setProfilePictureURL(URL.createObjectURL(file));
                        };
                        reader.onerror = function (error) {
                          console.log("Error: ", error);
                        };
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                    //setProfilePictureURL(croppedProfilePictureURL);
                    //setProfilePictureFile(croppedProfilePictureFile);
                    setProfilePictureModalVisible(false);
                  }}
                  style={{
                    width: 50,
                    height: 40,
                    borderRadius: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: usedTheme.PRIMARY,
                    borderWidth: 0,
                  }}
                >
                  <label style={{ fontSize: 16, color: "#fff" }}>
                    {i18n.t("Save")}
                  </label>
                </button>
              </div>
            </Modal>
          </CSSTransition>
          <div
            style={{
              width: isMobile ? "100%" : 600,
              minHeight: isMobile ? "100vh" : 500,
              backgroundColor: "#fff",
              borderRadius: isMobile ? 0 : 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
              marginBottom: isMobile ? 0 : 40,
            }}
          >
            <div
              className="get-btn"
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                position: "absolute",
                top: 10,
                right: 10,
                padding: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: usedTheme.PRIMARY,
                borderWidth: 0,
              }}
              onClick={() => {
                headerProfilePic.current.click();
              }}
            >
              <input
                type="file"
                id="file"
                ref={headerProfilePic}
                style={{ display: "none" }}
                onChange={(event) => {
                  if (event.target.files.length > 0) {
                    let file = event.target.files[0];
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      setHeaderPictureFile(reader.result);
                      setHeaderPictureURL(URL.createObjectURL(file));
                    };
                    reader.onerror = function (error) {
                      console.log("Error: ", error);
                    };
                  }
                }}
              />
              <MdModeEditOutline size={24} color={"#fff"} />
            </div>
            <div
              className="get-btn"
              style={{
                width: 26,
                height: 26,
                borderRadius: 20,
                position: "absolute",
                top: 52,
                right: 10,
                padding: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: usedTheme.PRIMARY,
                borderWidth: 0,
              }}
              onClick={() => {
                setHeaderPictureURL(DEFAULT_HEADERPICTURE);
              }}
            >
              <MdRefresh size={20} color={"#fff"} />
            </div>
            <img
              className="shadow_header"
              src={headerPictureURL}
              alt=""
              style={{
                width: "100%",
                height: 180,
                borderTopRightRadius: isMobile ? 0 : 20,
                borderTopLeftRadius: isMobile ? 0 : 20,
                resize: "contain",
              }}
            />
            <div
              style={{
                width: "100%",
                height: 120,
                marginTop: -80,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: 30,
              }}
            >
              <div
                style={{
                  width: 128,
                  height: 128,
                  borderRadius: 64,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  zIndex: 17,
                  position: "relative",
                }}
                onClick={() => {
                  setProfilePictureModalVisible(true);
                  //inputProfilePic.current.click();
                }}
              >
                <input
                  type="file"
                  id="file"
                  ref={inputProfilePic}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    if (event.target.files.length > 0) {
                      let file = event.target.files[0];
                      //console.log("FILE", file);
                      //return;
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        setCroppedProfilePictureFile(reader.result);
                        setCroppedProfilePictureURL(URL.createObjectURL(file));
                        /*
                        setProfilePictureFile(reader.result);
                        setProfilePictureURL(URL.createObjectURL(file));
                        */
                      };
                      reader.onerror = function (error) {
                        console.log("Error: ", error);
                      };
                    }
                  }}
                />
                <img
                  src={profilePictureURL}
                  alt=""
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: 60,
                    resize: "contain",
                    border: "0px solid #fff",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                marginTop: -90,
                paddingRight: 12,
              }}
            >
              {/*<div style={{ display: "none" }}>
                <QrCodeTemplate
                  ref={qrCodeRef}
                  size={72}
                  divSize={128}
                  uid={firebase.auth().currentUser?.uid}
                />
              </div>*/}
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 12,
                  padding: 10,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <QrCodeTemplate
                  ref={qrCodeRef}
                  size={72}
                  divSize={72}
                  uid={currentUser.uid} //firebase.auth().currentUser?.uid}
                />
                <MdOutlineSaveAlt
                  size={24}
                  color={usedTheme.PRIMARY}
                  style={{
                    alignSelf: "center",
                    justifySelf: "center",
                    marginTop: 8,
                  }}
                  onClick={() => {
                    handleSaveQR();
                  }}
                />
                {/*<button
                onClick={() => {
                  handleSaveQR();
                }}
                className="get-btn"
                style={
                  {
                  }
                }
              >
                Export QR Code
              </button>*/}
              </div>
            </div>
            <div
              className="shadow_section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                marginBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 20,
                paddingTop: 20,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {(allPrefixesVisible ? fullListPrefixes : prefixes).map(
                  (pr) => {
                    return (
                      <label
                        className={"pointer_hover"}
                        style={{
                          color: usedTheme.TEXT,
                          fontWeight: "500",
                          fontSize: 16,
                          padding: 4,
                          borderRadius: 8,
                          backgroundColor:
                            namePrefix == pr ? usedTheme.PRIMARY : "#fff",
                          marginRight: 6,
                          minWidth: 40,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setNamePrefix(pr);
                        }}
                      >
                        {pr}
                      </label>
                    );
                  }
                )}
                {!allPrefixesVisible && (
                  <label
                    className={"pointer_hover"}
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      padding: 4,
                      borderRadius: 8,
                      backgroundColor: namePrefix == "#fff",
                      marginRight: 6,
                      width: 40,
                      textAlign: "center",
                    }}
                    onClick={() => {
                      setAllPrefixesVisible(true);
                    }}
                  >
                    {"..."}
                  </label>
                )}
              </div>
              <div style={{ width: isMobile ? "80%" : "48%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                    marginLeft: 8,
                  }}
                >
                  {i18n.t("First Name")}
                </label>
                <input
                  className="form-control"
                  placeholder={i18n.t("Enter your first name")}
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 200,
                  }}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: isMobile ? "80%" : "48%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                    marginLeft: 8,
                  }}
                >
                  {i18n.t("Middle Name")}
                </label>
                <input
                  className="form-control"
                  placeholder={i18n.t("Enter your middle name")}
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 200,
                  }}
                  value={middleName}
                  onChange={(e) => {
                    setMiddleName(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                    marginLeft: 8,
                  }}
                >
                  {i18n.t("Last Name")}
                </label>
                <input
                  className="form-control"
                  placeholder={i18n.t("Enter your last name")}
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                    marginLeft: 8,
                  }}
                >
                  {i18n.t("About")}
                </label>
                <textarea
                  type="textarea"
                  aria-multiline={true}
                  className="form-control"
                  placeholder={i18n.t("About you")}
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                    marginLeft: 8,
                  }}
                >
                  {i18n.t("Birthday")}
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    justifyContent: "space-between",
                    maxWidth: 300,
                  }}
                >
                  <select
                    name="year"
                    id="years"
                    value={birthdayYear}
                    style={{ borderRadius: 4, borderColor: "#bbb" }}
                    onChange={(e) => {
                      setBirthdayYear(e.target.value);
                    }}
                  >
                    {years.map((x) => {
                      return <option value={x}>{x}</option>;
                    })}
                  </select>
                  <select
                    name="month"
                    id="months"
                    value={birthdayMonth}
                    style={{ borderRadius: 4, borderColor: "#bbb" }}
                    onChange={(e) => {
                      setBirthdayMonth(e.target.value);
                    }}
                  >
                    <option value="January">{i18n.t("January")}</option>
                    <option value="February">{i18n.t("February")}</option>
                    <option value="March">{i18n.t("March")}</option>
                    <option value="April">{i18n.t("April")}</option>
                    <option value="May">{i18n.t("May")}</option>
                    <option value="June">{i18n.t("June")}</option>
                    <option value="July">{i18n.t("July")}</option>
                    <option value="August">{i18n.t("August")}</option>
                    <option value="September">{i18n.t("September")}</option>
                    <option value="October">{i18n.t("October")}</option>
                    <option value="November">{i18n.t("November")}</option>
                    <option value="December">{i18n.t("December")}</option>
                  </select>
                  <select
                    name="day"
                    id="dates"
                    value={birthdayDay}
                    style={{ borderRadius: 4, borderColor: "#bbb" }}
                    onChange={(e) => {
                      setBirthdayDay(e.target.value);
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>
                </div>
              </div>
            </div>
            <div
              className="shadow_section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                marginBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 20,
                paddingTop: 20,
                zIndex: 993,
              }}
            >
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  <MdPhoneIphone
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  {i18n.t("Phone")}
                </label>
                <div
                  style={{
                    width: isMobile ? "90%" : "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ width: "80%" }}>
                    <PhoneInput
                      //className="form-control"
                      placeholder={i18n.t("Enter your phone number")}
                      style={{
                        color: usedTheme.TEXT,
                        fontSize: 14,
                        marginTop: -10,
                        marginBottom: 0,
                        width: "94%",
                        maxWidth: 300,
                        display: "flex",
                        flexDirection: "row",
                      }}
                      value={cellPhone}
                      onChange={(e) => {
                        setCellPhone(e);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: 90,
                      height: 50,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: -20,
                    }}
                  >
                    {/*<MdPhoneIphone
                    size={26}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                    }}
                  />*/}
                    <FaWhatsapp
                      size={24}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                      }}
                    />
                    <Switch
                      onChange={(e) => {
                        setCellPhoneHasWhatsapp(e);
                      }}
                      checked={cellPhoneHasWhatsapp}
                    />
                  </div>
                </div>
                {additionalPhoneNumbers.map((number, index) => {
                  return (
                    <div
                      style={{
                        width: isMobile ? "90%" : "90%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <PhoneInput
                          //className="form-control"
                          placeholder={i18n.t("Enter your phone number")}
                          style={{
                            color: usedTheme.TEXT,
                            fontSize: 14,
                            marginTop: 6,
                            marginBottom: 0,
                            width: "60%",
                            maxWidth: 300,
                            display: "flex",
                            flexDirection: "row",
                          }}
                          value={number.phoneNumber}
                          onChange={(e) => {
                            UpdateAdditionalPhoneNumber(index, {
                              phoneNumber: e,
                            });
                          }}
                        />
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 90,
                              height: 50,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginLeft: 8,
                            }}
                          >
                            <FaWhatsapp
                              size={24}
                              color={usedTheme.TEXT}
                              style={{
                                alignSelf: "center",
                                justifySelf: "center",
                              }}
                            />
                            <Switch
                              onChange={(e) => {
                                UpdateAdditionalPhoneNumber(index, {
                                  hasWhatsapp: e,
                                });
                              }}
                              checked={number.hasWhatsapp}
                            />
                          </div>
                          {number.hasWhatsapp ? (
                            <div stye={{ width: 60, marginTop: 6 }}></div>
                          ) : (
                            <input
                              className="form-control"
                              placeholder={i18n.t("ext")}
                              style={{
                                color: usedTheme.TEXT,
                                fontSize: 14,
                                marginTop: 6,
                                marginBottom: 0,
                                width: 60,
                              }}
                              value={number.phoneNumberExtension}
                              onChange={(e) => {
                                UpdateAdditionalPhoneNumber(index, {
                                  phoneNumberExtension: e.target.value,
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/*<input
                  className="form-control"
                  placeholder="Enter your phone number"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={cellPhone}
                  onChange={(e) => {
                    setCellPhone(e.target.value);
                  }}
                />*/}
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "400",
                    fontSize: 14,
                    marginTop: 4,
                  }}
                  onClick={() => {
                    AddAdditionalPhoneNumber();
                  }}
                >
                  + {i18n.t("Add additional phone number")}
                </label>
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  <MdEmail
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  {i18n.t("Email")}
                </label>
                <input
                  className="form-control"
                  placeholder="Enter your email"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  <MdWebAsset
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  {i18n.t("Website")}
                </label>
                <input
                  className="form-control"
                  placeholder="www.website.com"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={website}
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="shadow_section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                marginBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 20,
                paddingTop: 20,
                zIndex: 993,
              }}
            >
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "80%" }}>
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "800",
                      fontSize: 24,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Work")}
                  </label>
                </div>
              </div>
              <input
                type="file"
                id="file"
                ref={workProfilePic}
                style={{ display: "none" }}
                onChange={(event) => {
                  if (event.target.files.length > 0) {
                    let file = event.target.files[0];
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      setWorkPictureFile(reader.result);
                      setWorkPictureURL(URL.createObjectURL(file));
                    };
                    reader.onerror = function (error) {
                      console.log("Error: ", error);
                    };
                  }
                }}
              />
              <div
                style={{
                  width: "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginTop: -40,
                  marginBottom: -20,
                }}
              >
                {workPictureURL ? (
                  <img
                    className="pointer_hover"
                    src={workPictureURL}
                    style={{
                      height: 64,
                      color: "#fff",
                      borderRadius: 8,
                      width: 64,
                      alignSelf: "flex-end",
                    }}
                    onClick={() => {
                      workProfilePic.current.click();
                    }}
                    alt=""
                  />
                ) : (
                  <div
                    className="pointer_hover"
                    style={{
                      height: 64,
                      color: "#fff",
                      borderRadius: 8,
                      width: 64,
                      alignSelf: "flex-end",
                      backgroundColor: "#ddd",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      workProfilePic.current.click();
                    }}
                  >
                    <MdCameraAlt size={30} color={usedTheme.TEXT} style={{}} />
                  </div>
                )}
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  {i18n.t("Title")}
                </label>
                <input
                  className="form-control"
                  placeholder="title"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={workTitle}
                  onChange={(e) => {
                    setWorkTitle(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  {i18n.t("Company")}
                </label>
                <input
                  className="form-control"
                  placeholder={i18n.t("organization")}
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={workOrganization}
                  onChange={(e) => {
                    setWorkOrganization(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: isMobile ? "90%" : "90%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ width: "64%" }}>
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    <MdPhoneIphone
                      size={22}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        marginRight: 4,
                        marginLeft: 4,
                        marginTop: -4,
                      }}
                    />
                    {i18n.t("Phone")}
                  </label>
                  <PhoneInput
                    //className="form-control"
                    placeholder={i18n.t("Enter your phone number")}
                    style={{
                      color: usedTheme.TEXT,
                      fontSize: 14,
                      marginTop: -10,
                      marginBottom: 0,
                      width: "100%",
                      maxWidth: 300,
                      display: "flex",
                      flexDirection: "row",
                    }}
                    value={workPhone}
                    onChange={(e) => {
                      setWorkPhone(e);
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "20%",
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: 90,
                      height: 50,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: 8,
                    }}
                  >
                    <FaWhatsapp
                      size={24}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                      }}
                    />
                    <Switch
                      onChange={(e) => {
                        setWorkPhoneHasWhatsapp(e);
                      }}
                      checked={workPhoneHasWhatsapp}
                    />
                  </div>
                  <input
                    className="form-control"
                    placeholder={i18n.t("ext")}
                    style={{
                      color: usedTheme.TEXT,
                      fontSize: 14,
                      marginTop: 10,
                      marginBottom: 0,
                      width: 60,
                      height: 36,
                      marginLeft: 8,
                    }}
                    value={workPhoneExt}
                    onChange={(e) => {
                      setWorkPhoneExt(e.target.value);
                    }}
                  />
                </div>
              </div>
              {additionalWorkPhoneNumbers.map((number, index) => {
                return (
                  <div
                    style={{
                      width: isMobile ? "90%" : "90%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <PhoneInput
                        //className="form-control"
                        placeholder={i18n.t("Enter your phone number")}
                        style={{
                          color: usedTheme.TEXT,
                          fontSize: 14,
                          marginTop: 6,
                          marginBottom: 0,
                          width: "60%",
                          maxWidth: 300,
                          display: "flex",
                          flexDirection: "row",
                        }}
                        value={number.phoneNumber}
                        onChange={(e) => {
                          UpdateAdditionalWorkPhoneNumber(index, {
                            phoneNumber: e,
                          });
                        }}
                      />
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: 90,
                            height: 50,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginLeft: 8,
                          }}
                        >
                          <FaWhatsapp
                            size={24}
                            color={usedTheme.TEXT}
                            style={{
                              alignSelf: "center",
                              justifySelf: "center",
                            }}
                          />
                          <Switch
                            onChange={(e) => {
                              UpdateAdditionalWorkPhoneNumber(index, {
                                hasWhatsapp: e,
                              });
                            }}
                            checked={number.hasWhatsapp}
                          />
                        </div>
                        {number.hasWhatsapp ? (
                          <div stye={{ width: 80, marginTop: 6 }}></div>
                        ) : (
                          <input
                            className="form-control"
                            placeholder={i18n.t("ext")}
                            style={{
                              color: usedTheme.TEXT,
                              fontSize: 14,
                              marginTop: 6,
                              marginBottom: 0,
                              width: 80,
                            }}
                            value={number.phoneNumberExtension}
                            onChange={(e) => {
                              UpdateAdditionalWorkPhoneNumber(index, {
                                phoneNumberExtension: e.target.value,
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <label
                style={{
                  color: usedTheme.TEXT,
                  fontWeight: "400",
                  fontSize: 14,
                  marginTop: 4,
                }}
                onClick={() => {
                  AddAdditionalWorkPhoneNumber();
                }}
              >
                + {i18n.t("Add additional phone number")}
              </label>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  <MdEmail
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  {i18n.t("Email")}
                </label>
                <input
                  className="form-control"
                  placeholder={i18n.t("Enter your email")}
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={workEmail}
                  onChange={(e) => {
                    setWorkEmail(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  <MdWebAsset
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  {i18n.t("Website")}
                </label>
                <input
                  className="form-control"
                  placeholder={i18n.t("www.website.com")}
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginTop: -10,
                    marginBottom: 0,
                    width: "94%",
                    maxWidth: 300,
                  }}
                  value={workWebsite}
                  onChange={(e) => {
                    setWorkWebsite(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="shadow_section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                marginBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 20,
                paddingTop: 20,
                zIndex: 993,
              }}
            >
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "80%" }}>
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "800",
                      fontSize: 24,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Socail Media")}
                  </label>
                </div>
              </div>
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "40%" }}>
                  <FaLinkedin
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("LinkedIn")}
                  </label>
                </div>
                <input
                  className="form-control"
                  placeholder="@username"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginBottom: 0,
                    width: "54%",
                    maxWidth: 300,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  value={linkedIn}
                  onChange={(e) => {
                    setLinkedIn(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "40%" }}>
                  <FaSkype
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Skype")}
                  </label>
                </div>
                <input
                  className="form-control"
                  placeholder="@username"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginBottom: 0,
                    width: "54%",
                    maxWidth: 300,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  value={skype}
                  onChange={(e) => {
                    setSkype(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "40%" }}>
                  <FaFacebook
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Facebook")}
                  </label>
                </div>
                <input
                  className="form-control"
                  placeholder="@username"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginBottom: 0,
                    width: "54%",
                    maxWidth: 300,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  value={facebook}
                  onChange={(e) => {
                    setFacebook(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "40%" }}>
                  <FaTwitter
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Twitter")}
                  </label>
                </div>
                <input
                  className="form-control"
                  placeholder="@username"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginBottom: 0,
                    width: "54%",
                    maxWidth: 300,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  value={twitter}
                  onChange={(e) => {
                    setTwitter(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "40%" }}>
                  <FaInstagram
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Instagram")}
                  </label>
                </div>
                <input
                  className="form-control"
                  placeholder="@username"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginBottom: 0,
                    width: "54%",
                    maxWidth: 300,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  value={instagram}
                  onChange={(e) => {
                    setInstagram(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "40%" }}>
                  <FaBehance
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Behance")}
                  </label>
                </div>
                <input
                  className="form-control"
                  placeholder="@username"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginBottom: 0,
                    width: "54%",
                    maxWidth: 300,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  value={behance}
                  onChange={(e) => {
                    setBehance(e.target.value);
                  }}
                />
              </div>
              <div
                style={{
                  width: isMobile ? "84%" : "84%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 6,
                }}
              >
                <div style={{ width: "40%" }}>
                  <FaYoutube
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 4,
                      marginTop: -4,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "500",
                      fontSize: 16,
                      marginTop: 4,
                    }}
                  >
                    {i18n.t("Youtube")}
                  </label>
                </div>
                <input
                  className="form-control"
                  placeholder="@username"
                  style={{
                    color: usedTheme.TEXT,
                    fontSize: 14,
                    marginBottom: 0,
                    width: "54%",
                    maxWidth: 300,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  value={youtube}
                  onChange={(e) => {
                    setYoutube(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="shadow_section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 40,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 16,
                paddingTop: 6,
              }}
            >
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  {i18n.t("Profile url")}
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    disabled
                    className="form-control"
                    style={{
                      color: usedTheme.TEXT,
                      fontSize: 14,
                      marginBottom: 0,
                      width: "88%",
                      maxWidth: 500,
                      height: 34,
                    }}
                    value={profileURL}
                  />
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(profileURL);
                    }}
                    className="get-btn"
                    style={{
                      width: "10%",
                      padding: 0,
                      height: 32,
                      backgroundColor: usedTheme.PRIMARY,
                      borderWidth: 0,
                    }}
                  >
                    <FaRegCopy
                      size={22}
                      color={"#fff"}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        marginRight: 4,
                        marginLeft: 4,
                        marginTop: -4,
                      }}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="shadow_section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 40,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 16,
                paddingTop: 6,
              }}
            >
              <div
                style={{
                  width: isMobile ? "90%" : "90%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  {i18n.t("Language")}
                </label>

                <select
                  name="language"
                  id="languages"
                  value={language}
                  style={{
                    borderRadius: 4,
                    borderColor: "#bbb",
                    minWidth: 200,
                  }}
                  onChange={(e) => {
                    i18n.changeLanguage(e.target.value);
                    setLanguage(e.target.value);
                  }}
                >
                  {languages.map((x) => {
                    return <option value={x.value}>{x.label}</option>;
                  })}
                </select>
              </div>
              <div style={{ width: isMobile ? "90%" : "90%" }}>
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "500",
                    fontSize: 16,
                    marginTop: 4,
                  }}
                >
                  {i18n.t("Appearance")}
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {Themes_list.map((theme) => {
                    return (
                      <div
                        className="pointer_hover"
                        onClick={() => {
                          setUsedTheme(theme);
                        }}
                        style={{
                          borderRadius: 12,
                          padding: 8,
                          paddingBottom: 0,
                          backgroundColor:
                            theme.PRIMARY == usedTheme.PRIMARY &&
                            theme.SECONDARY == usedTheme.SECONDARY &&
                            theme.TEXT == usedTheme.TEXT
                              ? "#ddd"
                              : "#fff",
                          border:
                            theme.PRIMARY == usedTheme.PRIMARY &&
                            theme.SECONDARY == usedTheme.SECONDARY &&
                            theme.TEXT == usedTheme.TEXT
                              ? "3px solid #aaa"
                              : "0px solid #fff",
                          marginBottom: 8,
                        }}
                      >
                        <div
                          style={{
                            width: 100,
                            height: 40,
                            borderRadius: 12,
                            display: "flex",
                            flexDirection: "row",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              width: "50%",
                              height: "100%",
                              borderTopLeftRadius: 12,
                              borderBottomLeftRadius: 12,
                              backgroundColor: theme.PRIMARY,
                            }}
                          ></div>
                          <div
                            style={{
                              width: "50%",
                              height: "100%",
                              borderBottomRightRadius: 12,
                              borderTopRightRadius: 12,
                              backgroundColor: theme.SECONDARY,
                            }}
                          ></div>
                        </div>
                        <label
                          style={{
                            width: "100%",
                            fontSize: 16,
                            color: theme.TEXT,
                            textAlign: "center",
                          }}
                        >
                          {theme.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <div>
                <label style={{ color: "red" }}>{errorMessage}</label>
              </div>
              {profileUpdated ? (
                <div>
                  <label style={{ fontSize: 16, color: usedTheme.PRIMARY }}>
                    {i18n.t("Profile updated succesfully!")}
                  </label>
                </div>
              ) : (
                <></>
              )}

              <button
                onClick={() => {
                  handleSave();
                }}
                className="get-btn pointer_hover"
                style={{
                  backgroundColor: usedTheme.PRIMARY,
                  borderWidth: 0,
                }}
              >
                {loading ? (
                  <ClipLoader
                    color={"#fff"}
                    loading={loading}
                    css={override}
                    size={18}
                  />
                ) : (
                  <label
                    className="pointer_hover"
                    style={{ fontSize: 16, color: "#fff" }}
                  >
                    {i18n.t("Save Profile")}
                  </label>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUserInfoScreen;
