export const DEFAULT_THEME = {
  //PRIMARY: '#D4AF34',
  PRIMARY: "#f5ad29",
  SECONDARY: "#1F1012",
  //WHITE: '#E7ECEE',
  WHITE: "#fff",
  BLACK: "#000",
  //TEXT: '#1F1012',
  TEXT: "#000000",
};

export const Themes_list = [
  {
    name: "default",
    PRIMARY: "#f5ad29",
    SECONDARY: "#1F1012",
    WHITE: "#fff",
    BLACK: "#000",
    TEXT: "#000000",
  },
  {
    name: "theme 1",
    PRIMARY: "#57CC99",
    SECONDARY: "#627C85",
    WHITE: "#fff",
    BLACK: "#000",
    TEXT: "#627C85",
  },
  {
    name: "theme 2",
    PRIMARY: "#2d9bd6",
    SECONDARY: "#151515",
    WHITE: "#fff",
    BLACK: "#000",
    TEXT: "#151515",
  },
  {
    name: "theme 3",
    PRIMARY: "#9395d3",
    SECONDARY: "#000807",
    WHITE: "#fff",
    BLACK: "#000",
    TEXT: "#000807",
  },
];
