import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import CustomNavbar from "./component/CustomNavbar";
import Banner from "./component/Banner/Banner";
import About from "./component/About";
import Features from "./component/Features";
import Faq from "./component/Faq/Faq";
import Price from "./component/Price";
import Action from "./component/Action";
import AppStore from "./component/AppStore";
import Testimonial from "./component/Testimonial/Testimonial";
import Footer from "./component/Footer/Footer";
import $ from "jquery";

import WOW from "wowjs";
import EditUserScreen from "./EditUser";
import UserScreen from "./User";
class App extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      const wow = new WOW.WOW({
        live: false,
      });
      wow.init();
    }
    var head = $(".navbar");
    var stick = "shrink";
    //var stick = "hidden";
    $(window).scroll(function () {
      $(window).scrollTop() > 0
        ? head.addClass(stick)
        : head.removeClass(stick);
    });

    $(".navbar li a").on("click", function (event) {
      var $anchor = $(this);
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr("href")).offset().top - 75,
          },
          1500
        );
      event.preventDefault();
    });
  }
/*
  shouldComponentUpdate() {
    return false;
 }
 */
  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route
               exact
               path="/"
               render={() => {
                 return <Redirect to={"/home"} />;
               }}
            />
            <Route
              path="/home"
              component={() => {
                return (
                  <div className="body_wrapper">
                    <CustomNavbar />
                    <Banner />
                    {/*<Features />
                    <Faq />
                    <Price />
                    <Action />
                    <AppStore />
                    <Testimonial />*/}
                    <Footer />
                  </div>
                );
              }}
            />
            <Route exact path="/edit/:id" component={EditUserScreen} />
            <Route exact path="/user/:id" component={UserScreen} />
          </Switch>
          {/*<div className="body_wrapper">
                <CustomNavbar/>
                <Banner/>
                <Features/>
                <Faq/>
                <Price/>
                <Action/>
                <AppStore/>
                <Testimonial/>
                <Footer/>
            </div>*/}
        </div>
      </Router>
    );
  }
}

export default App;
