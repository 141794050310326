import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Modal from "react-modal";
import SignUpComponent from "../component/Modal/signup.component";
import LogInComponent from "../component/Modal/login.component";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { DEFAULT_THEME } from "../themes/themes";
import { CgClose } from "react-icons/cg";

export default function CustomNavbar() {
  const navigate = useHistory();
  const [usedTheme, setUsedTheme] = useState(DEFAULT_THEME);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const toggleAuthModal = () => {
    setIsAuthModalOpen(!isAuthModalOpen);
  };
  const [user, setUser] = useState(false);
  const { innerWidth: width, innerHeight: height } = window;
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, []);
  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged((resp) => {
      if (resp) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: resp?.uid,
            displayName: resp.displayName,
            photoURL: resp.photoURL,
            isSigned: true,
          })
        );
        setUser({ ...firebase.auth().currentUser });
        setIsAuthModalOpen(false);
      } else {
        localStorage.setItem("user", JSON.stringify({ isSigned: false }));
        setUser(false);
      }
    });
    return subscriber; // unsubscribe on unmount
  }, []);

  return (
    <nav
      id="fixed-top"
      className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg"
    >
      <CSSTransition in={isAuthModalOpen} timeout={300} classNames="dialog">
        <Modal
          closeTimeoutMS={500}
          isOpen={isAuthModalOpen}
          style={{
            content: {
              zIndex: 13,
              maxWidth: 400,
              minWidth: 340,
              width: "80%",
              height: 380,
              borderRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
              marginLeft: isMobile
                ? 0
                : width / 2 -
                  (width * 0.9 > 400
                    ? 400
                    : width * 0.9 < 300
                    ? 300
                    : 0.9 * width) /
                    2,
            },
            overlay: {
              backgroundColor: "#ffffff00",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              width: "90%",
            }}
          >
            <button
              onClick={toggleAuthModal}
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                backgroundColor: "#fff",
                borderWidth: 0,
              }}
            >
              <CgClose size={20} color={usedTheme.TEXT} />
            </button>
            {isSignUp ? (
              <SignUpComponent
                onClose={() => {
                  setIsSignUp(false);
                  toggleAuthModal();
                  if(firebase.auth()?.currentUser?.uid){
                    navigate.push("/edit/" + firebase.auth().currentUser.uid);}
                }}
              />
            ) : (
              <LogInComponent
                onClose={() => {
                  setIsSignUp(false);
                  toggleAuthModal();
                  if(firebase.auth()?.currentUser?.uid){
                  navigate.push("/edit/" + firebase.auth().currentUser.uid);}
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {isSignUp ? (
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "400",
                    fontSize: 16,
                  }}
                >
                  Already registered?{" "}
                  <label
                    style={{
                      color: "blue",
                      fontWeight: "500",
                      fontSize: 16,
                    }}
                    onClick={() => {
                      setIsSignUp(false);
                    }}
                  >
                    Log in
                  </label>
                </label>
              ) : (
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "400",
                    fontSize: 16,
                  }}
                >
                  Create an account?{" "}
                  <label
                    style={{
                      color: "blue",
                      fontWeight: "500",
                      fontSize: 16,
                    }}
                    onClick={() => {
                      setIsSignUp(true);
                    }}
                  >
                    Sign up
                  </label>
                </label>
              )}
            </div>
          </div>
        </Modal>
      </CSSTransition>
      <div className="container">
        <Link to="/">
          <span className="navbar-brand">
            <img
              src={require("../images/logo_knot.png")}
              alt="logo"
              style={{ maxHeight: 80, borderRadius:12 }}
            />
            <img
              src={require("../images/logo_knot.png")}
              alt="logo"
              style={{ maxHeight: 80, borderRadius:12 }}
            />
          </span>
        </Link>
        {/*<button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown submenu">
              <a
                className="nav-link dropdown-toggle active"
                href="#home"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Home
              </a>
              <ul className="dropdown-menu sub-menu">
                <li>
                  <a href="home.html">Home 01</a>
                </li>
                <li>
                  <a href="home-2.html">Home 02</a>
                </li>
                <li>
                  <a href="index.html">Home 03</a>
                </li>
                <li>
                  <a href="index3.html">Home 04</a>
                </li>
                <li>
                  <a href="index5.html">Home 05</a>
                </li>
                <li>
                  <a href="index6.html">Home 06</a>
                </li>
                <li>
                  <a href="index7.html">Home 07</a>
                </li>
                <li>
                  <a href="index8.html">Home 08</a>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#features">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#faq">
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#download">
                Download
              </a>
            </li>
          </ul>
        </div>
        */}
        {!!user ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <img
                className="pointer_hover"
                src={firebase.auth().currentUser.photoURL}
                style={{
                  height: 56,
                  color: "#fff",
                  borderRadius: 40,
                  width: 56,
                  border: "4px solid #f5ad29",
                }}
                onClick={() => {
                  navigate.push("/edit/" + firebase.auth().currentUser.uid);
                }}
                alt=""
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingRight: 20,
                    paddingLeft: 8,
                  }}
                >
                  <label
                    className="nav-item pointer_hover"
                    style={{
                      //color: usedTheme.TEXT,
                      fontSize: 20,
                      fontWeight: "bold",
                      marginBottom: -4,
                    }}
                    onClick={() => {
                      navigate.push("/edit/" + firebase.auth().currentUser.uid);
                    }}
                  >
                    {firebase.auth().currentUser.displayName}
                  </label>
                </div>
                <label
                  className="nav-item pointer_hover"
                  style={{
                    //color: usedTheme.TEXT,
                    fontSize: 10,
                    fontWeight: "400",
                    alignSelf: "flex-start",
                    marginTop: -4,
                    paddingLeft: 8,
                  }}
                  onClick={() => {
                    firebase.auth().signOut();
                  }}
                >
                  {"Signout"}
                </label>
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                toggleAuthModal();
              }}
              className="get-btn"
            >
              Sign In
            </div>
          )}
      </div>
    </nav>
  );
}
