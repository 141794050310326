import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import validator from "validator";
import { DEFAULT_THEME } from "../../themes/themes";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

export default function Login({ onClose }) {
  const navigate = useHistory();
  const [usedTheme, setUsedTheme] = useState(DEFAULT_THEME);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const validate = async () => {
    setError(false);
    if (!validator.isEmail(email)) {
      setError("Enter valid email");
      return;
    }
    if (password.length < 5) {
      setError("Password must be at least 6 characters");
      return;
    }
    signup();
  };
  const signup = async () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch(() => {
        setLoading(false);
        setError("Invalid credentials");
      });
  };

  return (
    <div style={{ width: "94%", alignSelf: "center" }}>
      <label
        style={{
          color: usedTheme.TEXT,
          fontWeight: "700",
          fontSize: 24,
          alignSelf: "center",
          textAlign: "center",
          width: "100%",
        }}
      >
        Login
      </label>
      <div>
        <label
          style={{ color: usedTheme.TEXT, fontWeight: "400", fontSize: 16 }}
        >
          Email
        </label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          style={{ color: usedTheme.TEXT, fontSize: 14 }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div>
        <label
          style={{
            color: usedTheme.TEXT,
            fontWeight: "400",
            fontSize: 16,
            marginTop: 8,
          }}
        >
          Password
        </label>
        <input
          type="password"
          className="form-control"
          placeholder="Enter password"
          style={{ color: usedTheme.TEXT, fontSize: 14 }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <div>
        <label style={{ color: "red" }}>{error}</label>
      </div>
      <button
        disabled={loading}
        className="btn btn-dark btn-lg btn-block"
        style={{
          backgroundColor: usedTheme.PRIMARY,
          borderWidth: 0,
          alignSelf: "center",
        }}
        onClick={() => {
          validate();
        }}
      >
        {loading ? (
          <ClipLoader
            color={'#fff'}
            loading={loading}
            css={override}
            size={18}
          />
        ) : (
          <label
            style={{ color: usedTheme.TEXT, fontWeight: "600", fontSize: 18 }}
          >
            Login
          </label>
        )}
      </button>
    </div>
  );
}
