import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/bootstrap.min.css';
import './assets/animate.css';
import './assets/elagent/style.css';
import './assets/themify-icon/themify-icons.css';
import './assets/font-awesome/font-awesome.min.css';
import './assets/main.css';
import './assets/responsive.css';
import firebase from 'firebase/compat/app';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

firebase.initializeApp({
    apiKey: "AIzaSyCwhIENpdjBFIMRrF24ur1AprvEcYGfay4",
    authDomain: "vcard-1bf26.firebaseapp.com",
    projectId: "vcard-1bf26",
    storageBucket: "vcard-1bf26.appspot.com",
    messagingSenderId: "250824499057",
    appId: "1:250824499057:web:d1732af54bbb7614ff60d0",
    measurementId: "G-JL6P1221M2"
  });

ReactDOM.render(  <React.StrictMode>
    <BrowserRouter>
       <App />
   </BrowserRouter>
 </React.StrictMode>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
