import React, { useRef } from "react";
import QRCode from "react-qr-code";
const FRONTEND_URL = "https://www.knot.services";
class QrCodeTemplate extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.divSize || 256,
          height: this.props.divSize || 256,
          //width: 100,
          //height:100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <QRCode
          size={this.props.size || 72}
          value={FRONTEND_URL + "/user/" + this.props.uid}
        />
      </div>
    );
  }
}

export default QrCodeTemplate;
