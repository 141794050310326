import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  SaveProfilePicture,
  SaveHeaderPicture,
  GetUserInfo,
  SaveUpdates,
  DownloadVCard,
} from "./API/api";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import QrCodeTemplate from "./component/qrCode.component";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import CustomNavbar from "./component/CustomNavbar";
import { DEFAULT_THEME } from "./themes/themes";
import {
  MdModeEditOutline,
  MdOutlineSaveAlt,
  MdEmail,
  MdPhoneIphone,
  MdWebAsset,
  MdCameraAlt,
} from "react-icons/md";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
  FaBehance,
  FaBirthdayCake,
  FaWhatsapp,
} from "react-icons/fa";
import { DEFAULT_PROFILEPICTURE } from "./API/api";
import HashLoader from "react-spinners/HashLoader";
import i18n from "./i18n";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const overrideHash = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

let prefixes = ["Mr.", "Mrs.", "Ms."];
function EditUserInfoScreen({ match }) {
  const navigate = useHistory();
  const inputProfilePic = useRef(null);
  const headerProfilePic = useRef(null);
  const qrCodeRef = useRef(null);

  const [downloading, setDownloading] = useState(false);
  const [usedTheme, setUsedTheme] = useState(DEFAULT_THEME);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, []);
  const [userInfo, setUserInfo] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState(
    DEFAULT_PROFILEPICTURE
  );

  useEffect(() => {
    if (match.params.id) {
      console.log(new Date());
      GetUserInfo({ id: match.params.id }).then((resp) => {
        if (!resp.userInfo) {
          return;
        }
        setUserInfo(resp?.userInfo);
        i18n.changeLanguage(resp?.userInfo?.language);
        setProfilePictureURL(resp?.userInfo?.profilePictureURL);
        setUsedTheme(resp?.userInfo?.THEME || DEFAULT_THEME);
        console.log(new Date());
      });
    }
  }, [match.params.id]);

  useEffect(() => {
    if (userInfo.profilePictureURL) {
      console.log(new Date());
      console.log(userInfo.profilePictureURL);
    }
  }, [userInfo.profilePictureURL]);
  const handleSaveQR = async () => {
    exportComponentAsPNG(qrCodeRef, {
      fileName:
        (userInfo?.firstName ? userInfo?.firstName : userInfo?.displayName) +
        (userInfo?.lastName ? " " + userInfo?.lastName : ""),

      pdfOptions: {
        x: 512,
        y: 512,
        w: 512,
        h: 512,
        unit: "px",
        orientation: "p",
      },
    });
  };

  let imgMyimageexample = require("./images/home7/banner-bg.png");
  let imgMybgexample = require("./images/bg1.jpg");
  if (!userInfo.profilePictureURL) {
    return (
      <div className="loading_screen">
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            //backgroundColor: "#d4af36",
            //backgroundImage: `url(${imgMyimageexample})`,
            headerPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <HashLoader
            color={"#fff"}
            loading={true}
            css={overrideHash}
            size={100}
          />
          {/*<img
            src={require("./images/animations/loading3.gif")}
            alt="loading..."
            style={{ width: isMobile ? "33%" : "16%" }}
          />*/}
        </div>
      </div>
    );
  } else {
    return (
      <div className="loading_screen">
        <div
          style={{
            width: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            //backgroundColor: "#403a2fff",
            //backgroundImage: `url(${imgMyimageexample})`,
            headerPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingBottom: isMobile ? 0 : 60,
            paddingTop: isMobile ? 0 : 60,
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : 600,
              minHeight: isMobile ? "100vh" : 500,
              backgroundColor: "#fff",
              borderRadius: isMobile ? 0 : 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            <img
              className="shadow_header"
              src={userInfo.headerPictureURL}
              alt=""
              style={{
                width: "100%",
                height: 160,
                borderTopRightRadius: isMobile ? 0 : 20,
                borderTopLeftRadius: isMobile ? 0 : 20,
                resize: "contain",
              }}
            />
            <div
              style={{
                width: "100%",
                height: 120,
                marginTop: -80,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                paddingLeft: 30,
              }}
            >
              <div
                style={{
                  width: 128,
                  height: 128,
                  borderRadius: 64,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  zIndex: 17,
                }}
              >
                <img
                  src={profilePictureURL}
                  alt=""
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: 60,
                    resize: "contain",
                    border: "0px solid #fff",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                marginTop: -90,
                paddingRight: 12,
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 12,
                  padding: 10,
                  paddingRight: isMobile ? 10 : 0,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  ref={qrCodeRef}
                  style={{
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                    paddingRight: isMobile ? 10 : 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <QrCodeTemplate
                    size={72}
                    divSize={72}
                    uid={match.params.id} //firebase.auth().currentUser?.uid}
                  />
                </div>
                <MdOutlineSaveAlt
                  size={24}
                  color={usedTheme.PRIMARY}
                  style={{
                    alignSelf: "center",
                    justifySelf: "center",
                    marginTop: 8,
                  }}
                  onClick={() => {
                    handleSaveQR();
                  }}
                />
              </div>
            </div>
            <div
              className="shadow_section"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                marginBottom: 16,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 12,
              }}
            >
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "bold",
                    fontSize: 28,
                    marginTop: -20,
                  }}
                >
                  {userInfo.namePrefix +
                    " " +
                    userInfo.firstName +
                    " " +
                    userInfo.lastName}
                </label>
              </div>
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <label
                  style={{
                    color: usedTheme.TEXT,
                    fontWeight: "400",
                    fontSize: 14,
                    lineHeight: 1.2,
                  }}
                >
                  {userInfo.description}
                </label>
              </div>
              {!!userInfo.website ? (
                <div style={{ width: isMobile ? "90%" : "90%" }}>
                  <MdWebAsset
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 0,
                      marginTop: -4,
                    }}
                  />
                  <a
                    className="pointers"
                    href={"https://" + userInfo.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <label
                      style={{
                        color: usedTheme.TEXT,
                        fontWeight: "400",
                        fontSize: 16,
                        marginTop: 4,
                      }}
                      className={"pointer_hover"}
                    >
                      {userInfo.website}
                    </label>
                  </a>
                </div>
              ) : (
                <></>
              )}
              {!!userInfo.email ? (
                <div style={{ width: isMobile ? "90%" : "90%" }}>
                  <MdEmail
                    className={"pointer_hover"}
                    size={22}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 0,
                    }}
                  />
                  <a
                    className="pointers"
                    href={"mailto:" + userInfo.email}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <label
                      className={"pointer_hover"}
                      style={{
                        color: usedTheme.TEXT,
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {userInfo.email}
                    </label>
                  </a>
                </div>
              ) : (
                <></>
              )}
              {!!userInfo.cellPhone ? (
                <div
                  className="pointers"
                  style={{ width: isMobile ? "90%" : "90%", marginTop: -10 }}
                >
                  {userInfo?.cellPhoneHasWhatsapp ? (
                    <FaWhatsapp
                      size={20}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        marginRight: 4,
                        marginLeft: 0,
                      }}
                    />
                  ) : (
                    <MdPhoneIphone
                      size={22}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        marginRight: 4,
                        marginLeft: 0,
                      }}
                    />
                  )}
                  <a
                    className="pointers"
                    href={
                      userInfo?.cellPhoneHasWhatsapp
                        ? "whatsapp://send?phone=" + userInfo.cellPhone
                        : "tel:" + userInfo.cellPhone
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <label
                      className="pointers"
                      style={{
                        color: usedTheme.TEXT,
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {userInfo.cellPhone}
                    </label>
                  </a>
                </div>
              ) : (
                <></>
              )}

              {userInfo?.additionalPhoneNumbers?.map((numb) => {
                return (
                  <div
                    style={{
                      width: isMobile ? "90%" : "90%",
                      marginTop: -10,
                    }}
                  >
                    <a
                      className="pointers"
                      href={
                        numb?.hasWhatsapp
                          ? "whatsapp://send?phone=" + numb.phoneNumber
                          : "tel:" + numb.phoneNumber
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {numb?.hasWhatsapp ? (
                        <FaWhatsapp
                          size={20}
                          color={usedTheme.TEXT}
                          style={{
                            alignSelf: "center",
                            justifySelf: "center",
                            marginRight: 4,
                            marginLeft: 0,
                          }}
                        />
                      ) : (
                        <MdPhoneIphone
                          size={22}
                          color={usedTheme.TEXT}
                          style={{
                            alignSelf: "center",
                            justifySelf: "center",
                            marginRight: 4,
                            marginLeft: 0,
                          }}
                        />
                      )}
                      <label
                        className="pointers"
                        style={{
                          color: usedTheme.TEXT,
                          fontWeight: "400",
                          fontSize: 14,
                        }}
                      >
                        {numb.phoneNumber +
                          (numb.phoneNumberExtension
                            ? " / ext " + numb.phoneNumberExtension
                            : "")}
                      </label>
                    </a>
                  </div>
                );
              })}
              {!!userInfo.birthdayYear &&
              !!userInfo.birthdayMonth &&
              !!userInfo.birthdayDay ? (
                <div
                  style={{ width: isMobile ? "90%" : "90%", marginTop: -10 }}
                >
                  <FaBirthdayCake
                    size={18}
                    color={usedTheme.TEXT}
                    style={{
                      alignSelf: "center",
                      justifySelf: "center",
                      marginRight: 4,
                      marginLeft: 0,
                    }}
                  />
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "400",
                      fontSize: 14,
                    }}
                  >
                    {userInfo.birthdayDay +
                      "-" +
                      userInfo.birthdayMonth +
                      "-" +
                      userInfo.birthdayYear}
                  </label>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="shadow_section"
              style={{
                width: "94%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 6,
                paddingTop: 6,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignSelf: "center",
                  justifyContent: "space-bwteen",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: isMobile ? "66%" : "66%",
                    marginTop: -4,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    {userInfo.workTitle}
                  </label>
                  <label
                    style={{
                      color: usedTheme.TEXT,
                      fontWeight: "400",
                      fontSize: 16,
                      marginTop: -12,
                    }}
                  >
                    {userInfo.workOrganization}
                  </label>
                  {!!userInfo.workEmail ? (
                    <div style={{ width: "100%" }}>
                      <MdEmail
                        className={"pointer_hover"}
                        size={22}
                        color={usedTheme.TEXT}
                        style={{
                          alignSelf: "center",
                          justifySelf: "center",
                          marginRight: 4,
                          marginLeft: 0,
                        }}
                      />
                      <a
                        href={"mailto:" + userInfo.workEmail}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <label
                          className={"pointer_hover"}
                          style={{
                            color: usedTheme.TEXT,
                            fontWeight: "400",
                            fontSize: 14,
                          }}
                        >
                          {userInfo.workEmail}
                        </label>
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!!userInfo.workPhone ? (
                    <div
                      style={{
                        width: isMobile ? "90%" : "90%",
                        marginTop: -10,
                      }}
                    >
                      <a
                        className="pointers"
                        //href={"whatsapp://send?phone=" + userInfo.workPhone}
                        href={
                          userInfo?.workPhoneHasWhatsapp
                            ? "whatsapp://send?phone=" + userInfo.workPhone
                            : "tel:" + userInfo.workPhone
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {userInfo?.workPhoneHasWhatsapp ? (
                          <FaWhatsapp
                            size={20}
                            color={usedTheme.TEXT}
                            style={{
                              alignSelf: "center",
                              justifySelf: "center",
                              marginRight: 4,
                              marginLeft: 0,
                            }}
                          />
                        ) : (
                          <MdPhoneIphone
                            size={22}
                            color={usedTheme.TEXT}
                            style={{
                              alignSelf: "center",
                              justifySelf: "center",
                              marginRight: 4,
                              marginLeft: 0,
                            }}
                          />
                        )}
                        <label
                          className="pointers"
                          style={{
                            color: usedTheme.TEXT,
                            fontWeight: "400",
                            fontSize: 14,
                          }}
                        >
                          {userInfo.workPhone +
                            (userInfo.workPhoneExt
                              ? " / ext " + userInfo.workPhoneExt
                              : "")}
                        </label>
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                  {userInfo?.additionalWorkPhoneNumbers?.map((numb) => {
                    return (
                      <div
                        style={{
                          width: isMobile ? "90%" : "90%",
                          marginTop: -10,
                        }}
                      >
                        <a
                          className="pointers"
                          href={
                            numb?.hasWhatsapp
                              ? "whatsapp://send?phone=" + numb.phoneNumber
                              : "tel:" + numb.phoneNumber
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {numb?.hasWhatsapp ? (
                            <FaWhatsapp
                              size={20}
                              color={usedTheme.TEXT}
                              style={{
                                alignSelf: "center",
                                justifySelf: "center",
                                marginRight: 4,
                                marginLeft: 0,
                              }}
                            />
                          ) : (
                            <MdPhoneIphone
                              size={22}
                              color={usedTheme.TEXT}
                              style={{
                                alignSelf: "center",
                                justifySelf: "center",
                                marginRight: 4,
                                marginLeft: 0,
                              }}
                            />
                          )}
                          <label
                            className="pointers"
                            style={{
                              color: usedTheme.TEXT,
                              fontWeight: "400",
                              fontSize: 14,
                            }}
                          >
                            {numb.phoneNumber +
                              (numb.phoneNumberExtension
                                ? " / ext " + numb.phoneNumberExtension
                                : "")}
                          </label>
                        </a>
                      </div>
                    );
                  })}
                  {!!userInfo.workWebsite ? (
                    <div style={{ width: isMobile ? "90%" : "90%" }}>
                      <MdWebAsset
                        size={22}
                        color={usedTheme.TEXT}
                        style={{
                          alignSelf: "center",
                          justifySelf: "center",
                          marginRight: 4,
                          marginLeft: 0,
                          marginTop: -4,
                        }}
                      />
                      <a
                        href={"https://" + userInfo.workWebsite}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <label
                          style={{
                            color: usedTheme.TEXT,
                            fontWeight: "400",
                            fontSize: 16,
                            marginTop: 4,
                          }}
                          className={"pointer_hover"}
                        >
                          {userInfo.workWebsite}
                        </label>
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {userInfo.workPictureURL ? (
                  <div
                    style={{
                      width: isMobile ? "30%" : "30%",
                      marginTop: -8,
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={userInfo.workPictureURL}
                      style={{
                        height: 64,
                        color: "#fff",
                        borderRadius: 8,
                        width: 64,
                        alignSelf: "flex-end",
                      }}
                      alt=""
                    />{" "}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {(!!userInfo.linkedIn ||
              !!userInfo.facebook ||
              !!userInfo.youtube ||
              !!userInfo.twitter ||
              !!userInfo.instagram ||
              !!userInfo.behance) && (
              <div
                className="shadow_section"
                style={{
                  width: "94%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 40,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingBottom: 6,
                  paddingTop: 6,
                }}
              >
                {!!userInfo.linkedIn ? (
                  <a
                    className="pointers"
                    href={"https://www.linkedin.com/in/" + userInfo.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin
                      size={30}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        margin: 8,
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
                {!!userInfo.youtube ? (
                  <a
                    className="pointers"
                    href={"https://www.youtube.com/" + userInfo.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube
                      size={30}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        margin: 8,
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
                {!!userInfo.twitter ? (
                  <a
                    className="pointers"
                    href={"https://www.twitter.com/" + userInfo.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter
                      size={30}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        margin: 8,
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
                {!!userInfo.facebook ? (
                  <a
                    className="pointers"
                    href={"https://www.facebook.com/" + userInfo.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook
                      size={30}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        margin: 8,
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
                {!!userInfo.instagram ? (
                  <a
                    className="pointers"
                    href={"https://www.instagram.com/" + userInfo.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram
                      size={30}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        margin: 8,
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
                {!!userInfo.behance ? (
                  <a
                    className="pointers"
                    href={"https://www.behance.com/" + userInfo.behance}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaBehance
                      size={30}
                      color={usedTheme.TEXT}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        margin: 8,
                      }}
                    />
                  </a>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <button
                disabled={downloading}
                onClick={() => {
                  setDownloading(true);
                  DownloadVCard({
                    name: userInfo?.firstName + " " + userInfo?.lastName,
                    id: userInfo?.uid,
                  })
                    .then(() => {
                      setDownloading(false);
                    })
                    .catch(() => {
                      setDownloading(false);
                    });
                }}
                className="get-btn"
                style={{
                  padding: 10,
                  paddingRight: 16,
                  paddingLeft: 16,
                  borderRadius: 12,
                  paddingTop: 14,
                  backgroundColor: usedTheme.PRIMARY,
                  borderWidth: 0,
                  color: "#fff",
                  fontSize: 16,
                  lineHeight: 1,
                  fontWeight: "600",
                  minWidth: 120,
                }}
              >
                {downloading ? (
                  <ClipLoader
                    color={"#fff"}
                    loading={downloading}
                    css={override}
                    size={18}
                  />
                ) : (
                  <>
                    <label
                      className="pointer_hover"
                      style={{ fontSize: 16, color: "#fff" }}
                    >
                      {i18n.t("Download contact")}
                    </label>
                    <MdOutlineSaveAlt
                      size={24}
                      color={"#fff"}
                      style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        marginLeft: 8,
                      }}
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUserInfoScreen;
