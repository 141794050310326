import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

//const BACKEND_URL = 'http://192.168.1.6:3999';
//const BACKEND_URL = 'http://localhost:3999';
//const BACKEND_URL = "https://knotservices.herokuapp.com";
const BACKEND_URL = "https://vcard-backend.herokuapp.com"

//https://firebasestorage.googleapis.com/v0/b/vcard-1bf26.appspot.com/o/users%2Fdefault%20business%20man.png?alt=media
export const DEFAULT_PROFILEPICTURE =
  "https://firebasestorage.googleapis.com/v0/b/vcard-1bf26.appspot.com/o/users%2Fdefault%20business%20man.png?alt=media";

export const DEFAULT_HEADERPICTURE =
  "https://firebasestorage.googleapis.com/v0/b/vcard-1bf26.appspot.com/o/users%2F0default%2Fdefault%20header%202.jpg?alt=media";

export async function DownloadVCard({ id, name }) {
  return await axios
    .get(BACKEND_URL + "/users/vcard", { params: { id }, headers: {} })
    .then((response) => {
      console.log("RESP:", response.data);
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = name + ".vcf";
      link.click();
      link.remove();
      return true;
    })
    .catch((error) => {
      console.log("ERROR:", error);
      return false;
    });
}

export async function GetUserInfo_({ id }) {
  return await axios
    .get(BACKEND_URL + "/users/userinfo", {
      params: { id },
      headers: {},
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("ERROR:", error);
    });
}

export const GetUserInfo = async ({ id }) => {
  let userInfo = await firebase
    .firestore()
    .collection("users")
    .doc(id)
    .get()
    .then((resp) => {
      if (resp) {
        let usrInfo = { ...resp.data(), uid: id };
        return usrInfo;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
  return { userInfo };
};

export async function SaveProfilePicture({ id, profilePicture }) {
  let imageURL = await AddMediaToStorage(
    "users/" + id,
    profilePicture,
    "profilePicture"
  );
  try {
    firebase.auth().currentUser.updateProfile({ photoURL: imageURL });
  } catch {}
  try {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update({ profilePictureURL: imageURL });
  } catch {}
}

export async function SaveHeaderPicture({ id, headerPicture }) {
  let imageURL = await AddMediaToStorage(
    "users/" + id,
    headerPicture,
    "headerPicture"
  );

  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .update({ headerPictureURL: imageURL });
}

export async function SaveWorkPicture({ id, workPicture }) {
  let imageURL = await AddMediaToStorage(
    "users/" + id,
    workPicture,
    "workPicture"
  );
  try {
    firebase
      .firestore()
      .collection("users")
      .doc(id)
      .update({ workPictureURL: imageURL });
  } catch {}
}

async function AddMediaToStorage(path, media, name) {
  let type = media.substring(11, media.indexOf(";"));
  let typeExtension = "jpg";

  if (type == "jpg" || type == "jpeg") {
    typeExtension = "jpg";
  } else if ((type = "png")) {
    typeExtension = "png";
  } else {
    typeExtension = type;
  }
  //let imageFile = media.replace("data:image/" + type + ";base64,", "");
  //imageFile = imageFile.replace("data:application/octet-stream;base64,", "");
  let imageFile = media.replace(
    media.substring(0, media.indexOf(";base64,") + 8),
    ""
  );
  return await firebase
    .storage()
    .ref(path + "/")
    .child(name + new Date().valueOf().toString() + "." + typeExtension)
    .putString(imageFile, "base64", { contentType: "image/" + type })
    .then(async (res) => {
      return res.ref.getDownloadURL().then((downloadURL) => {
        return downloadURL;
      });
    })
    .catch((error) => {
      console.log("ADD MEDIA ERROR", error);
      return false;
    });
}

export async function SaveUpdates({ id, updates }) {
  firebase
    .firestore()
    .collection("users")
    .doc(id)
    .update({ ...updates });
}
